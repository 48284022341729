import React from 'react';
import as from '../AssetsComponents/12345.png';

const NoTipsAvailable = () => {
  return (
    <div style={styles.container}>
      <div style={styles.imageContainer}>
        <img
          src={as}
          alt="No Data"
          style={styles.illustration}
        />
      </div>
      <h2 style={styles.noTipsText}>No tips available!</h2>
      <p style={styles.purchaseText}>
        Please purchase Tip Package or Monthly Subscription.
      </p>
      <button
        style={styles.purchaseButton}
        onMouseOver={(e) => e.target.style.backgroundColor = styles.purchaseButtonHover.backgroundColor}
        onMouseOut={(e) => e.target.style.backgroundColor = styles.purchaseButton.backgroundColor}
      >
        Purchase Package
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh',
    backgroundColor: '#ffffff',
    padding: '5%', // Adjusted padding for better mobile experience
    boxSizing: 'border-box',
    position: 'relative', // Added relative positioning for the container
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    position: 'relative', // Added relative positioning for image container
  },
  illustration: {
    width: '100%', // Full width for small screens
    maxWidth: '700px', // Adjusted for better visibility
    height: 'auto', // Maintain aspect ratio
  },
  noTipsText: {
    color: '#ff6f00',
    fontSize: '2.5rem', // Larger text for visibility
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
    position: 'relative', // Added relative positioning for the text
  },
  purchaseText: {
    color: '#888888',
    fontSize: '1.2rem', // Increased size for readability
    marginBottom: '30px',
    textAlign: 'center',
    position: 'relative', // Added relative positioning for the text
  },
  purchaseButton: {
    backgroundColor: '#ff6f00',
    color: 'white',
    padding: '12px 24px',
    border: 'none',
    borderRadius: '30px',
    fontSize: '1.2rem', // Slightly larger button text
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    width: '100%', // Full width on small screens
    maxWidth: '250px', // Increased max width for larger screens
    position: 'relative', // Added relative positioning for the button
  },
  purchaseButtonHover: {
    backgroundColor: '#ff8f00',
  }
};

export default NoTipsAvailable;
