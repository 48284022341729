import React, { useState } from 'react';
import '../StyleComponents/Checkout.css'
import credit2 from '../AssetsComponents/Credit2.png'
import credit1 from '../AssetsComponents/credit1.png'
import HeaderComponent from '../ReusableComponent/HeaderComponent';
import logo from '../AssetsComponents/logoMain.png'
import image25 from '../AssetsComponents/youtube.png'
import image26 from '../AssetsComponents/twitter.png'


const Checkout = () => {
    const [paymentMethod, setPaymentMethod] = useState('credit-card');
    const [cardholderName, setCardholderName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCVV] = useState('');
    const [postalCode, setPostalCode] = useState('');

    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit payment logic here
        console.log({
            cardholderName,
            cardNumber,
            expiryDate,
            cvv,
            postalCode,
        });
    };

    return (
        <>
            <HeaderComponent />

            <h2 className='h22'>Checkout</h2>

            <div className="checkout-container">
                <form onSubmit={handleSubmit}>
                    <div className="payment-method">
                        <h3>Payment and Billing</h3>
                        <div className="payment-options">
                            <label>
                                <input
                                    type="radio"
                                    value="credit-card"
                                    checked={paymentMethod === 'credit-card'}
                                    onChange={() => handlePaymentMethodChange('credit-card')}
                                />
                                <img src={credit2} alt="Stripe" className="stripe-logo" />
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="stripe"
                                    checked={paymentMethod === 'stripe'}
                                    onChange={() => handlePaymentMethodChange('stripe')}
                                />
                                <img src={credit1} alt="Stripe" className="stripe-logo" />
                            </label>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Cardholder name</label>
                        <input
                            type="text"
                            placeholder="John Doe"
                            value={cardholderName}
                            onChange={(e) => setCardholderName(e.target.value)}
                            required
                            className='input11'

                        />
                    </div>

                    <div className="form-group">
                        <label>Cardholder Number</label>
                        <input
                            type="text"
                            placeholder="0123 4567 8901 1234"
                            value={cardNumber}
                            onChange={(e) => setCardNumber(e.target.value)}
                            required
                            className='input11'
                        />
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Expiry Date</label>
                            <input
                                type="text"
                                placeholder="01/29"
                                value={expiryDate}
                                onChange={(e) => setExpiryDate(e.target.value)}
                                required
                                className='input10'

                            />
                        </div>
                        <div className="form-group">
                            <label>CVV</label>
                            <input
                                type="text"
                                placeholder="000"
                                value={cvv}
                                onChange={(e) => setCVV(e.target.value)}
                                required
                                className='input10'

                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Postal Code</label>
                        <input
                            type="text"
                            placeholder="0123456"
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                            required
                            className='input11'

                        />
                    </div>

                    <button type="submit" className="pay-button">Pay</button>
                </form>
            </div>
            <div className="get-started-sections">
                {/* <div className="container">
                    <div className="content">
                        <h1>Get Started Today</h1>
                        <p>Elevate your horse racing experience with the best tips and insights in the industry. Sign up now and start making smarter betting decisions.</p>
                        <div className="buttons">
                            <button className="sign-up">Sign Up Now</button>
                            <button className="latest-tips">View Latest Tips</button>
                        </div>
                    </div>
                    <img src={image24} alt="Whooska Logo" className="HorseRace" />
                </div> */}

                <div className="footers">
                    <img src={logo} alt="Whooska Logo" className="logos4" />

                    <div className="footer-link">
                        <a href="#">Home</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Tips</a>
                        <div className="vertical-lines"></div>
                        <a href="/WhyWhooskaComponents">About Us</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Contact Us</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Privacy Policy</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Terms & Conditions</a>
                    </div>

                </div>
                <div className="footer-containers1">
                    <div className="left-sides">
                        <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
                    </div>
                    <p className="centered-paragraphs">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C', textDecoration: 'none' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
                    <div className="right-sides4">
                        <img src={image25} alt="Logo 1" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
                        <img src={image26} alt="Logo 2" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
                    </div>
                </div>

            </div>

        </>
    );
};

export default Checkout;
