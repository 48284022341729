import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate for navigation
import '../StyleComponents/LoginComponents.css'; // Import the CSS file for styling
import logo from '../AssetsComponents/logoMain.png';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Importing eye icons from react-icons
import image25 from '../AssetsComponents/youtube.png';
import image26 from '../AssetsComponents/twitter.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [errors, setErrors] = useState({}); // State to handle form errors

  const navigate = useNavigate(); // Initialize useNavigate for navigation

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate form fields
    let formErrors = {};
    if (!email) {
      formErrors.email = "Email is required";
    }
    if (!password) {
      formErrors.password = "Password is required";
    }

    // If there are errors, update the state, else navigate to home
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      // Navigate to the home page after successful login
      navigate('/YourTipsComponents');
    }
  };

  return (
    <>
      <div className="header-container7">
        <header className="header7">
          <div className="logo7">
            <img src={logo} alt="Logo" />
          </div>
        </header>
      </div>

      <div className="login-container">
        <div className="login-card">
          <img src={logo} alt="logo" className="login-logo" />
          <h2 className="login-title">Log In</h2>
          <p className="login-subtitle">Please enter your details to Sign In</p>
          <form onSubmit={handleSubmit} className="login-form">
            <div className="input-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="user.whooska@gmail.com"
              // required
              />
              {errors.email && <p className="error-text">{errors.email}</p>} {/* Display email error */}
            </div>
            <div className="input-group password-group">
              <label>Password</label>
              <input
                type={showPassword ? 'text' : 'password'}  // Toggles between text and password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="**********"
              // required
              />
              <span
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)} // Toggles the showPassword state
              >
                {/* {showPassword ? <FiEyeOff /> : <FiEye />} Eye icon to toggle password visibility */}
              </span>
              {errors.password && <p className="error-text">{errors.password}</p>} {/* Display password error */}
            </div>
            <div className="forgot-password">
              <Link to="/ForgotPassword">Forgot Password?</Link> {/* Link to Forgot Password */}
            </div>
            <button type="submit" className="login-btn">Log In</button>
          </form>
          <div className="create-account">
            Don’t have an account? <Link to="/RegisterComponent">Create Account</Link>
          </div>
        </div>
      </div>
      <div className="get-started-sections">
        <div className="footer-container1">
          <div className="left-side">
            <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
          </div>
          <p className="centered-paragraph">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
          <div className="right-side4">
            <img src={image25} alt="Logo 1" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
            <img src={image26} alt="Logo 2" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;












































// import React, { useState } from 'react';
// import '../StyleComponents/WhooskaSubscription.css';
// import HeaderComponent from '../ReusableComponent/HeaderComponent';
// import check from '../AssetsComponents/check.png'
// import { FaExclamationCircle } from 'react-icons/fa'; // Importing Font Awesome icons
// import horse from '../AssetsComponents/horseRacing.png'
// import logo from '../AssetsComponents/logoMain.png'
// import image25 from '../AssetsComponents/youtube.png'
// import image26 from '../AssetsComponents/twitter.png'



// function App() {
//     const [checkedStates, setCheckedStates] = useState({
//         melbourne: false,
//         sydney: false,
//         brisbane: false,
//     });

//     const handleCheckboxChange = (card) => {
//         setCheckedStates((prevState) => ({
//             ...prevState,
//             [card]: !prevState[card],
//         }));
//     };

//     return (
//         <>
//             <HeaderComponent />
//             <div className="image-containers" style={{ position: 'relative', width: '100%', height: '500px' }}>
//                 <img
//                     src={horse}
//                     alt="Banner"
//                     style={{ height: '60%', width: '100%', objectFit: 'cover', filter: 'brightness(50%)' }}
//                 />
//                 <div
//                     className="text-overlays"
//                     style={{
//                         position: 'absolute',
//                         top: '30%',
//                         left: '67%',
//                         transform: 'translate(-50%, -50%)',
//                         color: 'white',
//                         fontSize: '34px',
//                         fontWeight: 'bold',
//                         zIndex: '99999',
//                         width: '40%'
//                     }}
//                 >
//                     <p>Subscription Plans
//                     </p>
//                 </div>
//             </div>
//             <div className="container">
//                 {/* Subscription Cards Section */}
//                 <div className="subscription-cards">
//                     {/* Melbourne Tips Card */}
//                     <div className="card">
//                         <div className="card-header">
//                             <h3>Melbourne Tips</h3>
//                             <input
//                                 type="checkbox"
//                                 className="checkbox"
//                                 checked={checkedStates.melbourne}
//                                 onChange={() => handleCheckboxChange('melbourne')}
//                             />
//                         </div>
//                         <div className="price-row">
//                             <h4>$20 <span>/month</span></h4>
//                             <span className="highlight">Saturday Tips</span>
//                         </div>
//                         <ul>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >
//                                     Tips for every race at your favourite metro tracks. The Full Whooska Set!!
//                                 </span>
//                             </li>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >   Recommended Unit Bet/Strategy.

//                                 </span>
//                             </li>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >
//                                     Tips received by 11am Saturday morning via email.
//                                 </span>
//                             </li>
//                         </ul>
//                         <button>Add to cart</button>
//                     </div>

//                     {/* Sydney Tips Card */}
//                     <div className="card">
//                         <div className="card-header">
//                             <h3>Sydney Tips</h3>
//                             <input
//                                 type="checkbox"
//                                 className="checkbox"
//                                 checked={checkedStates.sydney}
//                                 onChange={() => handleCheckboxChange('sydney')}
//                             />
//                         </div>
//                         <div className="price-row">
//                             <h4>$20 <span>/month</span></h4>
//                             <span className="highlight">Saturday Tips</span>
//                         </div>
//                         <ul>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >
//                                     Tips for every race at your favourite metro tracks. The Full Whooska Set!!
//                                 </span>
//                             </li>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >   Recommended Unit Bet/Strategy.

//                                 </span>
//                             </li>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >
//                                     Tips received by 11am Saturday morning via email.
//                                 </span>
//                             </li>
//                         </ul>
//                         <button>Add to cart</button>
//                     </div>

//                     {/* Brisbane Tips Card */}
//                     <div className="card">
//                         <div className="card-header">
//                             <h3>Brisbane Tips</h3>
//                             <input
//                                 type="checkbox"
//                                 className="checkbox"
//                                 checked={checkedStates.brisbane}
//                                 onChange={() => handleCheckboxChange('brisbane')}
//                             />
//                         </div>
//                         <div className="price-row">
//                             <h4>$20 <span>/month</span></h4>
//                             <span className="highlight">Saturday Tips</span>
//                         </div>
//                         <ul>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >
//                                     Tips for every race at your favourite metro tracks. The Full Whooska Set!!
//                                 </span>
//                             </li>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >   Recommended Unit Bet/Strategy.

//                                 </span>
//                             </li>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >
//                                     Tips received by 11am Saturday morning via email.
//                                 </span>
//                             </li>

//                         </ul>
//                         <button>Add to cart</button>
//                     </div>

//                     {/* Whooska Ultimate Package Card */}
//                     <div className="card1 premium-card1">
//                         <div className="card-header1">
//                             <h3>Whooska Ultimate Package</h3>
//                         </div>
//                         <div className="price-row">
//                             <h4>$20 <span>/month</span></h4>
//                         </div>
//                         <ul>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >
//                                     <span style={{ color: "black", fontWeight: 'bold' }}>Purchase Tips</span> - Melbourne, Sydney, Brisbane.
//                                 </span>
//                             </li>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >Daily best bets (7 days a week) - Where Whooska's are available.
//                                 </span>
//                             </li>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >Quadie selections for Saturday tracks.
//                                 </span>
//                             </li>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >Access to Discord group chat to be part of the Whooska Community.
//                                 </span>
//                             </li>
//                             <li style={{ display: 'flex', alignItems: 'flex-start' }}>
//                                 <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
//                                 <span >Tips received by 11am Saturday morning via email.
//                                 </span>
//                             </li>
//                         </ul>
//                         <button>Add to cart</button>
//                     </div>
//                 </div>
//             </div>

//             <div className="note-container">
//       <div className="note-header">
//         <FaExclamationCircle className="note-icon" />
//         <span className="note-title">Please note</span>
//       </div>
//       <ul className="note-list">
//         <li>Tips will be emailed to your registered address and also shared on the discord group chat.</li>
//         <li>Monthly subscriptions will automatically be processed each month unless cancelled.</li>
//         <li>
//           If you cancel your monthly membership during the current month, you will not be refunded your monthly
//           subscription. However, your subscription will be cancelled from future transactions.
//         </li>
//       </ul>
//     </div>

//             <div className="view-cart">
//                 <button>View Cart</button>
//             </div>
//             <div className="get-started-sections">
//                 {/* <div className="container">
//                     <div className="content">
//                         <h1>Get Started Today</h1>
//                         <p>Elevate your horse racing experience with the best tips and insights in the industry. Sign up now and start making smarter betting decisions.</p>
//                         <div className="buttons">
//                             <button className="sign-up">Sign Up Now</button>
//                             <button className="latest-tips">View Latest Tips</button>
//                         </div>
//                     </div>
//                     <img src={image24} alt="Whooska Logo" className="HorseRace" />
//                 </div> */}

//                 <div className="footers">
//                     <img src={logo} alt="Whooska Logo" className="logos4" />

//                     <div className="footer-link">
//                         <a href="#">Home</a>
//                         <div className="vertical-lines"></div>
//                         <a href="#">Tips</a>
//                         <div className="vertical-lines"></div>
//                         <a href="/WhyWhooskaComponents">About Us</a>
//                         <div className="vertical-lines"></div>
//                         <a href="#">Contact Us</a>
//                         <div className="vertical-lines"></div>
//                         <a href="#">Privacy Policy</a>
//                         <div className="vertical-lines"></div>
//                         <a href="#">Terms & Conditions</a>
//                     </div>

//                 </div>
//                 <div className="footer-containers1">
//                     <div className="left-sides">
//                         <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
//                     </div>
//                     <p className="centered-paragraphs">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C', textDecoration: 'none' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
//                     <div className="right-sides4">
//                         <img src={image25} alt="Logo 1" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
//                         <img src={image26} alt="Logo 2" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
//                     </div>
//                 </div>

//             </div>

//         </>
//     );
// }

// export default App;
