import React, { useState } from 'react';
import '../StyleComponents/ProfileForm.css'
import image50 from '../AssetsComponents/image50.png'
import HeaderComponent from '../ReusableComponent/HeaderComponentMain';
import image25 from '../AssetsComponents/youtube.png';
import image26 from '../AssetsComponents/twitter.png';
import logo from '../AssetsComponents/logoMain.png';
import Footer from '../ReusableComponent/FooterResuble'

const ProfileForm = () => {
    const [formData, setFormData] = useState({
        name: 'John Doe',
        email: 'user.whooska@gmail.com',
        mobilePrefix: '+61',
        mobileNumber: '900 0000 000',
        address: '1549 Glory Road, Aurora, Colorado, United States',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <>
            <HeaderComponent />

            <div className="profile-form-container">
                <div className="profile-header">
                    <img
                        src={image50} // replace with actual image URL
                        alt="User Profile"
                        className="profile-picture"
                    />
                    <span className="edit-icon">✏️</span>
                </div>

                <form className="profile-form">
                    <div className="form-group33">
                        <label style={{ color: 'grey', fontSize: 12 }}>Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="input-field33"
                            placeholder='Name'
                        />
                    </div>

                    <div className="form-group33">
                        <label style={{ color: 'grey', fontSize: 12 }}>Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="input-field33"
                            placeholder='Email'

                        />
                    </div>

                    <div className="form-group33">
                        <label style={{ color: 'grey', fontSize: 12 }}>Mobile Number</label>
                        <div className="mobile-number-container33">
                            <input
                                type="text"
                                name="mobilePrefix"
                                value={formData.mobilePrefix}
                                onChange={handleChange}
                                className="input-field33 mobile-prefix"
                                placeholder='+61'

                            />
                            <input
                                type="text"
                                name="mobileNumber"
                                value={formData.mobileNumber}
                                onChange={handleChange}
                                className="input-field33 mobile-number33"
                                placeholder='Mobile'

                            />
                        </div>
                    </div>

                    <div className="form-group33">
                        <label style={{ color: 'grey', fontSize: 12 }}>Address</label>
                        <textarea
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            className="input-field33"
                            placeholder='Address'

                        />
                    </div>

                    <button type="button" className="edit-profile-button">
                        Edit Profile
                    </button>
                </form>
            </div>
            <Footer/>

        </>
    );
};

export default ProfileForm;
