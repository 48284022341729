import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import '../StyleComponents/ChangePassword.css';
import logo from '../AssetsComponents/logoMain.png';
import image25 from '../AssetsComponents/youtube.png';
import image26 from '../AssetsComponents/twitter.png';

const ChangePassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (newPassword.length < 8) {
            setError('Password must be at least 8 characters long');
            return;
        }

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        // Handle password change logic here
        setError(''); // Clear errors if validation passes
        console.log('Password successfully changed');
    };

    return (
        <>
            <div className="header-container7">
                <header className="header7">
                    <div className="logo7">
                        <img src={logo} alt="Logo" />
                    </div>
                </header>
            </div>
            <div style={styles.container}>
                <div style={styles.card}>
                    <h2 style={styles.title}>Change password</h2>
                    <form onSubmit={handleSubmit}>
                        <div style={styles.inputContainer}>
                            <label style={styles.label}>New Password</label>
                            <div style={styles.passwordWrapper}>
                                <input
                                    type={showNewPassword ? 'text' : 'password'}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    style={styles.input}
                                    autoComplete="off"
                                />
                                <button
                                    type="button"
                                    onClick={toggleNewPasswordVisibility}
                                    style={styles.eyeButton}
                                >
                                    {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>
                        </div>

                        <div style={styles.inputContainer}>
                            <label style={styles.label}>Confirm New Password</label>
                            <div style={styles.passwordWrapper}>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    style={styles.input}
                                    autoComplete="off"
                                />
                                <button
                                    type="button"
                                    onClick={toggleConfirmPasswordVisibility}
                                    style={styles.eyeButton}
                                >
                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>
                        </div>

                        {error && <p style={styles.error}>{error}</p>}

                        <button type="submit" style={styles.submitButton}>
                            Submit
                        </button>
                    </form>
                </div>
            </div>
            <div className="get-started-sections">
                <div className="footer-container1">
                    <div className="left-side">
                        <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
                    </div>
                    <p className="centered-paragraph">
                        This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.
                    </p>
                    <div className="right-side4">
                        <img src={image25} alt="Logo 1" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
                        <img src={image26} alt="Logo 2" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
                    </div>
                </div>
            </div>
        </>

    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'white',
    },
    card: {
        backgroundColor: '#fff',
        padding: '40px',
        borderRadius: '10px',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
        width: '350px',
        textAlign: 'center',
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#ff8c00',
        marginBottom: '20px',
    },
    inputContainer: {
        marginBottom: '20px',
    },
    label: {
        display: 'block',
        textAlign: 'left',
        marginBottom: '8px',
        fontSize: '14px',
        fontWeight: '500',
        color: '#333',
    },
    passwordWrapper: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    input: {
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '16px',
        paddingRight: '40px',
        appearance: 'none',
    },
    eyeButton: {
        position: 'absolute',
        right: '10px',
        background: 'none',
        border: 'none',
        fontSize: '16px',
        cursor: 'pointer',
        color: '#666',
    },
    submitButton: {
        background: 'linear-gradient(to right, #ff8c00, #ff4c00)',
        color: '#fff',
        padding: '12px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        fontSize: '16px',
    },
    error: {
        color: 'red',
        marginBottom: '10px',
    },
};

export default ChangePassword;
