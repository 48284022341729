import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import '../StyleComponents/ForgotPasswordVerifyCode.css';
import logo from '../AssetsComponents/logoMain.png';
import image25 from '../AssetsComponents/youtube.png';
import image26 from '../AssetsComponents/twitter.png';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '']);
    const navigate = useNavigate(); // Initialize navigate

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Email:', email);
        console.log('Verification Code:', verificationCode.join(''));

        // Add logic to check verification code here
        // For example, if the code is correct, navigate to the desired page
        navigate('/ChangePassword'); // Replace with the route you want to navigate to
    };

    // Update individual code inputs and auto-focus next input
    const handleCodeChange = (value, index) => {
        const updatedCode = [...verificationCode];
        updatedCode[index] = value;
        setVerificationCode(updatedCode);

        if (value && index < verificationCode.length - 1) {
            document.getElementById(`code-${index + 1}`).focus();
        }
    };

    return (
        <>
            <div className="header-container7">
                <header className="header7">
                    <div className="logo7">
                        <img src={logo} alt="Logo" />
                    </div>
                </header>
            </div>
            <div className="forgot-password-container1">
                <div className="forgot-password-box1">
                    <h2>Forgot Password</h2>
                    <p>Enter your registered email to reset your password</p>

                    <form onSubmit={handleSubmit}>
                        <label className="info-text2">Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="user.whooska@gmail.com"
                            required
                        />

                        <p style={{ marginTop: '-10px' }}>We've sent a code to your email. Please enter it below.</p>
                        <p className="info-text1">Verification code</p>

                        <div className="verification-code-container">
                            {verificationCode.map((code, index) => (
                                <input
                                    key={index}
                                    id={`code-${index}`}
                                    type="text"
                                    maxLength="1"
                                    value={code}
                                    onChange={(e) => handleCodeChange(e.target.value, index)}
                                    className="verification-code-input"
                                />
                            ))}
                        </div>

                        <p className="resend-code">Resend Code</p>

                        <button type="submit" className="verify-button">Verify Code</button>
                    </form>
                </div>
            </div>
            <div className="get-started-sections">
                <div className="footer-container1">
                    <div className="left-side">
                        <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
                    </div>
                    <p className="centered-paragraph">
                        This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.
                    </p>
                    <div className="right-side4">
                        <img src={image25} alt="Logo 1" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
                        <img src={image26} alt="Logo 2" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
