import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import '../StyleComponents/SignupAuthentication.css';
import logo from '../AssetsComponents/logoMain.png';
import image25 from '../AssetsComponents/youtube.png';
import image26 from '../AssetsComponents/twitter.png';

const AuthenticationForm = () => {
    const [verificationCode, setVerificationCode] = useState(new Array(6).fill(""));

    const navigate = useNavigate(); // Initialize useNavigate for navigation

    const handleChange = (element, index) => {
        const value = element.value;
        if (/^[0-9]$/.test(value)) {
            let newCode = [...verificationCode];
            newCode[index] = value;
            setVerificationCode(newCode);

            // Move focus to the next input field automatically
            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Assuming successful verification
        if (verificationCode.join("").length === 6) {
            // Navigate to a different route after successful verification
            navigate('/LoginComponents'); // Change '/success' to the route you want to navigate to
        } else {
            console.error("Incomplete Verification Code"); // Add validation if needed
        }
    };

    return (
        <>
            <div className="header-container7">
                <header className="header7">
                    <div className="logo7">
                        <img src={logo} alt="Logo" />
                    </div>
                </header>
            </div>
            <div className="container12">
                <div className="form-wrapper">
                    <h1 className="title">Authentication</h1>
                    <p className="info-text">
                        We've sent a code to your email. Please enter it below.
                    </p>
                    <form onSubmit={handleSubmit}>
                        <p className="info-text">Verification code</p>
                        <div className="verification-input-wrapper">
                            {verificationCode.map((code, index) => (
                                <input
                                    className="input"
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={code}
                                    onChange={(e) => handleChange(e.target, index)}
                                />
                            ))}
                        </div>
                        <button type="submit" className="button">Verify Code</button>
                    </form>
                    <a className="resend-code" onClick={() => console.log("Code Resent!")}>
                        Resend Code
                    </a>
                </div>
            </div>
            {/* <div className="get-started-sections">
                <div className="footer-container1">
                    <div className="left-side">
                        <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
                    </div>
                    <p className="centered-paragraph">
                        This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.
                    </p>
                    <div className="right-side4">
                        <img src={image25} alt="Logo 1" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
                        <img src={image26} alt="Logo 2" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
                    </div>
                </div>
            </div> */}
            <div className="get-started-sections">
                <div className="footer-container1">
                    <div className="left-side">
                        <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
                    </div>
                    <p className="centered-paragraph">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
                    <div className="right-side4">
                        <img src={image25} alt="Logo 1" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
                        <img src={image26} alt="Logo 2" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthenticationForm;
