import { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import logo from '../AssetsComponents/logoMain.png';
import '../StyleComponents/HeaderComponentMain.css';
import profile from '../AssetsComponents/profile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faBars } from '@fortawesome/free-solid-svg-icons';
import error from '../AssetsComponents/error.png';

function HeaderComponent() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [activeDropdownItem, setActiveDropdownItem] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [hamburgerOpen, setHamburgerOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const navigate = useNavigate();

    const menuItems = [
        { name: 'Home', path: '/' },
        { name: 'Why Whooska', path: '/WhyWhooskaComponents' },
        { name: 'Results', path: '/WhooskaResultsComponents' },
        { name: 'Free Tips', path: '/WhooskaFreeTipsComponents' },
        { name: 'Purchase Tips', path: '/WhooskaSubscription' },
        { name: 'Your Tips', path: '/YourTipsComponents' },
        { name: 'Contact', path: '/ContactForm' },
    ];

    const dropdownItems = [
        { name: 'Profile', path: '/ProfileForm' },
        { name: 'Purchase History', path: '/PurchaseHistory' },
        { name: 'Log Out' },
    ];

    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    };

    const toggleHamburgerMenu = () => {
        setHamburgerOpen(prev => !prev);
    };

    const handleLinkClick = (item, path) => {
        setHamburgerOpen(false);
        navigate(path);
    };

    const handleDropdownItemClick = (item) => {
        if (item === 'Log Out') {
            setModalOpen(true);
        } else {
            setActiveDropdownItem(item);
            const path = dropdownItems.find(i => i.name === item)?.path;
            if (path) {
                navigate(path);
            }
            setDropdownOpen(false);
        }
    };

    const confirmLogout = () => {
        console.log('Logging out...');
        navigate('/LoginComponents');
    };

    const cancelLogout = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownOpen && !event.target.closest('.auth-links11')) {
                setDropdownOpen(false);
            }
            if (hamburgerOpen && !event.target.closest('.hamburgerMenu')) {
                setHamburgerOpen(false);
            }
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('resize', handleResize);
        };
    }, [dropdownOpen, hamburgerOpen]);

    return (
        <div className="header-containers11">
            <header className="headers11">
                <div className="logos11">
                    <NavLink to="/LoginComponents">
                        <img src={logo} alt="Logos11" style={styles.logoImage} />
                    </NavLink>
                </div>
                <div className="nav-containers11" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <nav className="navs11">
                        <ul style={{ display: 'flex', listStyle: 'none', padding: '0', margin: '0' }}>
                            {menuItems.map(({ name, path }) => (
                                <li key={name}>
                                    <NavLink
                                        to={path}
                                        onClick={() => handleLinkClick(name, path)}
                                        style={({ isActive }) => ({
                                            color: isActive ? '#F7812A' : 'black',
                                            textDecoration: 'none',
                                        })}
                                    >
                                        {name}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className="auth-links11" style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: '15px' }}>
                        {/* Show profile section only on desktop */}
                        {!isMobile && (
                            <div
                                className="profile"
                                onClick={toggleDropdown}
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                aria-expanded={dropdownOpen}
                                role="button"
                            >
                                <img src={profile} alt="Profile" className="profile-image" style={{ borderRadius: '50%', width: '40px', height: '40px' }} />
                                <span className="profile-name" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                                    John Deo
                                    <FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: '5px', color: '#000' }} />
                                </span>
                                {dropdownOpen && (
                                    <div className="profile-dropdown" style={styles.dropdown}>
                                        {dropdownItems.map(({ name }) => (
                                            <div
                                                key={name}
                                                onClick={() => handleDropdownItemClick(name)}
                                                style={activeDropdownItem === name ? styles.optionActive : styles.option}
                                            >
                                                {name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                        {isMobile && (
                            <FontAwesomeIcon 
                                icon={faBars} 
                                onClick={toggleHamburgerMenu} 
                                style={{ cursor: 'pointer', display: 'block' }} 
                            />
                        )}

                        {hamburgerOpen && (
                            <div className="hamburgerMenu" style={styles.hamburgerMenu}>
                                {menuItems.map(({ name, path }) => (
                                    <div key={name} onClick={() => handleLinkClick(name, path)} style={styles.hamburgerItem} role="menuitem">
                                        {name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </header>

            {modalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img src={error} alt="Error" className="modal-icon" />
                        </div>
                        <div className="modal-body">
                            <h2>Log Out</h2>
                            <p>Are you sure you want to Log Out?</p>
                        </div>
                        <div className="modal-footer">
                            <button onClick={confirmLogout} className="deactivate-btn">Logout</button>
                            <button onClick={cancelLogout} className="cancel-btn">Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const styles = {
    dropdown: {
        width: '200px',
        borderRadius: '10px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#fff',
        padding: '10px',
        fontFamily: 'Arial, sans-serif',
        position: 'absolute',
        zIndex: 1000,
        marginLeft: -80,
        top: '100%',
    },
    option: {
        padding: '10px',
        marginBottom: '5px',
        cursor: 'pointer',
        borderRadius: '5px',
        color: '#000',
    },
    optionActive: {
        padding: '10px',
        marginBottom: '5px',
        cursor: 'pointer',
        borderRadius: '5px',
        backgroundColor: '#333',
        color: '#fff',
    },
    hamburgerMenu: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '60px',
        left: '0',
        backgroundColor: '#fff',
        width: '100%',
        borderRadius: '10px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
    },
    hamburgerItem: {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: '1px solid #eee',
        color: 'black',
    },
};

export default HeaderComponent;
