import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../StyleComponents/RegisterComponent.css';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import logo from '../AssetsComponents/logoMain.png'
import image25 from '../AssetsComponents/youtube.png'
import image26 from '../AssetsComponents/twitter.png'

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [errors, setErrors] = useState({}); // To handle validation errors

    const navigate = useNavigate(); // Initialize useNavigate

    const handleSubmit = (e) => {
        e.preventDefault();

        // Reset errors
        let validationErrors = {};
        let formIsValid = true;

        // Validation logic
        if (!name) {
            formIsValid = false;
            validationErrors['name'] = "Name is required.";
        }

        if (!email) {
            formIsValid = false;
            validationErrors['email'] = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formIsValid = false;
            validationErrors['email'] = "Email is invalid.";
        }

        if (!mobile) {
            formIsValid = false;
            validationErrors['mobile'] = "Mobile number is required.";
        } else if (!/^[0-9]{10}$/.test(mobile)) {
            formIsValid = false;
            validationErrors['mobile'] = "Mobile number should be 10 digits.";
        }

        if (!password) {
            formIsValid = false;
            validationErrors['password'] = "Password is required.";
        } else if (password.length < 6) {
            formIsValid = false;
            validationErrors['password'] = "Password must be at least 6 characters.";
        }

        if (password !== confirmPassword) {
            formIsValid = false;
            validationErrors['confirmPassword'] = "Passwords do not match.";
        }

        if (!termsAccepted) {
            formIsValid = false;
            validationErrors['termsAccepted'] = "You must accept the terms.";
        }

        // If form is valid, navigate to another page
        if (formIsValid) {
            // Perform form submission logic here

            // After successful submission, navigate to another page
            navigate('/SignupAuthentication'); // Change '/success' to the route you want to navigate to
        }

        setErrors(validationErrors);
    };

    return (
        <>
            <div className="header-container7">
                <header className="header7">
                    <div className="logo7">
                        <img src={logo} alt="Logo" />
                    </div>
                </header>
            </div>
            <div className="register-container">
                <div className="register-card">
                    <h2 className="register-title">Register</h2>
                    <p className="register-subtitle">Create an account to access all tips and more!</p>
                    <form onSubmit={handleSubmit} className="register-form">

                        <div className="input-group">
                            <label>Name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="John Doe"
                            // required
                            />
                            {errors.name && <p className="error-message">{errors.name}</p>}
                        </div>

                        <div className="input-group">
                            <label>Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="user.whooska@gmail.com"
                            // required
                            />
                            {errors.email && <p className="error-message">{errors.email}</p>}
                        </div>

                        <div className="input-group">
                            <label>Mobile number</label>
                            <div className="mobile-input-group">
                                <input type="text" value="+61" readOnly className="country-code" />
                                <input
                                    type="tel"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    placeholder="900 0000 000"
                                // required
                                />
                            </div>
                            {errors.mobile && <p className="error-message">{errors.mobile}</p>}
                        </div>

                        <div className="input-group password-groups">
                            <label>Password</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="**********"
                            // required
                            />
                            <span
                                className="password-toggle"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {/* {showPassword ? <FiEyeOff /> : <FiEye />} */}
                            </span>
                            {errors.password && <p className="error-message">{errors.password}</p>}
                            <p className="password-info">
                                Password must be 6-20 characters long, including at least one number, one uppercase letter, one lowercase letter, and one non-alphanumeric character.
                            </p>
                        </div>

                        <div className="input-group password-groups">
                            <label>Confirm Password</label>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="**********"
                            // required
                            />
                            {errors.confirmPassword && <p className="error-message">{errors.confirmPassword}</p>}
                        </div>

                        <div className="terms-group">
                            <input
                                type="checkbox"
                                id="termsCheckbox"
                                checked={termsAccepted}
                                onChange={() => setTermsAccepted(!termsAccepted)}
                                // required
                                className="checkbox1"
                            />
                            <label>
                                I agree with the <a href="/terms">Terms & Conditions</a> & <a href="/privacy">Privacy Policy</a>.
                            </label>
                        </div>
                        {/* {errors.termsAccepted && <p className="error-message">{errors.termsAccepted}</p>} */}


                        <button type="submit" className="register-btn">Sign Up</button>
                    </form>

                    <div className="login-link">
                        Already have an account? <a href="/LoginComponents">Log In</a>
                    </div>
                </div>
            </div>
            <div className="get-started-sections">

                <div className="footer-container1">
                    <div className="left-side">
                        <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
                    </div>
                    <p className="centered-paragraph">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
                    <div className="right-side4">
                        <img src={image25} alt="Logo 1" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
                        <img src={image26} alt="Logo 2" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
