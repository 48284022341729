import React from 'react'
import '../StyleComponents/WhooskaFreeTipsComponents.css'
import logo from '../AssetsComponents/logoMain.png'
import image18 from '../AssetsComponents/Vector.png'
import { FaMapMarkerAlt } from 'react-icons/fa';
import horse from '../AssetsComponents/horseRacing.png'
import image25 from '../AssetsComponents/youtube.png'
import image26 from '../AssetsComponents/twitter.png'
import { Link } from 'react-router-dom';
import HeaderComponent from '../ReusableComponent/HeaderComponent';
import Footer from '../ReusableComponent/FooterResuble'








const Tip = ({ number, track, raceNo, horseName, bettingUnit, analysis, location }) => {
    return (
        <div className="tip-container">
            <div className="tip-header">
                <h3>Free Tips #{number}</h3>
                <div className="location">
                    <span role="img" aria-label="location">
                        <FaMapMarkerAlt style={{ color: 'orange', fontSize: '15px' }} />
                    </span>
                    {location}
                </div>
            </div>

            {/* Table for displaying details */}
            <hr className="divider" /> {/* Horizontal line */}

            <table className="tip-table">
                <thead>
                    <tr>
                        <th>Track</th>
                        <th>Race No</th>
                        <th>Horse Name</th>
                        <th>Recommended Betting Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='image111'>
                            <img src={image18} alt="Track" className="track-image" />
                            <span className="track-text">{track}</span>
                        </td>

                        <td>{raceNo}</td>
                        <td>{horseName}</td>
                        <td>{bettingUnit}</td>
                    </tr>
                </tbody>
            </table>

            <div className="horse-analysis-container">
                <hr className="divider" /> {/* Horizontal line */}
                <div className="horse-analysis">
                    <strong style={{ color: '#ff9500' }}>Horse Analysis</strong>
                    <p>{analysis}</p>
                </div>
            </div>
        </div>
    );
};


function WhooskaFreeTipsComponents() {
    return (
        <>
            <HeaderComponent />

            <div className="image-containers" style={{ position: 'relative', width: '100%', height: '500px' }}>
                <img
                    src={horse}
                    alt="Banner"
                    style={{ height: '60%', width: '100%', objectFit: 'cover', filter: 'brightness(50%)' }}
                />
                <div
                    className="text-overlays"
                    style={{
                        position: 'absolute',
                        top: '30%',
                        left: '43%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        fontSize: '34px',
                        fontWeight: 'bold',
                        zIndex: '99999',
                        width: '90%'
                    }}
                >
                    <p className='FreeWhooska '>Free Whooska Tips</p>
                </div>
            </div>
            <div className="App">
                <div className="tips-expiration">
                    <p>Tips to expire Saturday at 10pm, Australian Eastern Standard Time (AEST)</p>
                </div>
                <Tip
                    number={1}
                    track="BM78"
                    raceNo="01"
                    horseName="Forecaster"
                    bettingUnit="$7 - 0.5u"
                    analysis="Looks the one to beat for me. Lost last start by 0.9L over 1500m. Before that lost by 0.7L to The Extreme Cat which is flying over 1400m. Step up to 1800m here, but has won at 1600m. Good price to find out if it can run out a strong 1800m. If it does, it should win."
                    location="Melbourne"
                />
                <Tip
                    number={2}
                    track="BM78"
                    raceNo="02"
                    horseName="Sequestered"
                    bettingUnit="$3.1 - 1.5u"
                    analysis="Was a painful watch last start, got clear very late and would have won if it did sooner. Ran the 2nd fastest 400-200m of the day and 4th fastest last furlong of the day with 11.34s. I am sticking fat here."
                    location="Sydney"
                />
                <Tip
                    number={3}
                    track="BM78"
                    raceNo="03"
                    horseName="Zarrose"
                    bettingUnit="$6 - 0.5u"
                    analysis="This horse won over 1400m soft5 last start in slick time from 7th in the field. Ran the 2nd fastest 4-200m of the day and the fastest last 200m of the day in 11.16s. That is some serious speed."
                    location="Brisbane"
                />
            </div>
            <Footer/>


        </>

    )
}

export default WhooskaFreeTipsComponents