import React from 'react'
import image24 from '../AssetsComponents/IMG_48.png'
import image25 from '../AssetsComponents/youtube.png'
import image26 from '../AssetsComponents/twitter.png'
import logo from '../AssetsComponents/logoMain.png'
import '../StyleComponents/FooterResuble.css';
import { Link } from 'react-router-dom';

function FooterResuble() {
    return (
        <div className="new-get-started-section">
            <div className="new-container">
                <div className="new-content">
                    <h1 style={{ textAlign: 'left' }} className='new-heading'>Get Started Today</h1>
                    <p className='new-paragraph'>Elevate your horse racing experience with the best tips and insights in the industry. Sign up now and start making smarter betting decisions.</p>
                    <div className="new-buttons">
                        <button className="new-sign-up">Sign Up Now</button>
                        <button className="new-latest-tips">View Latest Tips</button>
                    </div>
                </div>
            </div>

            <div className="new-footer">
                <img src={logo} alt="Whooska Logo" className="new-logo4" />
                <div className="new-footer-links">
                    <Link to="/">Home</Link>
                    <div className="new-vertical-line"></div>
                    <Link to="/WhooskaFreeTipsComponents">Tips</Link>
                    <div className="new-vertical-line"></div>
                    <Link to="/WhyWhooskaComponents">About Us</Link>
                    <div className="new-vertical-line"></div>
                    <Link to="/ContactForm">Contact Us</Link>
                    <div className="new-vertical-line"></div>
                    <Link to="#">Privacy Policy</Link>
                    <div className="new-vertical-line"></div>
                    <Link to="#">Terms & Conditions</Link>
                </div>
            </div>

            <div className="new-footer-container">
                <div className="new-left-side">
                    <p className="new-copyright-text" style={{ color: '#FAD45C' }}>copyright @ whooska</p>
                </div>
                <p className="new-centered-paragraph">
                    This page is for 18+ only. Gamble Responsibly. Visit <a href="#" className="new-gambling-link">www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span className="new-gambling-number">1800 858 858</span>. The chances are you are about to lose.
                </p>
                <div className="new-right-side">
                    <img src={image25} alt="Logo 1" className="new-log9" />
                    <img src={image26} alt="Logo 2" className="new-log9" />
                </div>
            </div>
        </div>
    )
}

export default FooterResuble